import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import classes from './weather.module.css';
import Heroine from '../components/Heroine';
import Intro from '../components/Intro';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className={classes.weather}>
      <div className={classes.weatherOverlay}></div>
      <Heroine />
      <Intro />
    </div>
  </Layout>
);

export default IndexPage;
