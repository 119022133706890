import React from 'react';

const Intro = () => {
  return (
    <div
      style={{
        color: 'white',
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'flex-start',
      }}
    >
      <p
        style={{
          fontSize: '1.2rem',
          lineHeight: '2rem',
          fontWeight: 400,
          maxWidth: 'calc(300px + 16rem)',
        }}
      >
        Met Éireann have 2054 entries on data.gov.ie. As well as their main
        Weather Forecast API, Weather Warnings & Live Text Forecast feeds
        there's a load of other information available in varying formats and
        from different endpoints.
      </p>
      <p
        style={{
          fontSize: '1.2rem',
          lineHeight: '2rem',
          maxWidth: 'calc(300px + 16rem)',
          fontWeight: 400,
        }}
      >
        This project is an attempt to make (some of) it available in one place
        and in the same format.
      </p>
    </div>
  );
};

export default Intro;
